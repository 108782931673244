<template>
  <div class="container mb-48">
    <div
      class="w-full h-full flex items-center justify-items-center justify-center"
    >
      <div class="w-full h-full flex flex-wrap">
        <div class="w-full lg:w-1/2 mt-12 mb-4">
          <div
            class="h-full flex items-center justify-center justify-items-center"
          >
            <div class="flex flex-wrap w-full">
              <div
                class="w-full mb-12 flex items-center justify-center justify-items-center"
              >
                <img
                  class="object-contain"
                  src="@/assets/mog/login1.svg"
                  alt=""
                />
              </div>

              <div
                class="w-full flex items-center justify-center justify-items-center"
              >
                <img
                  class="object-contain"
                  src="@/assets/mog/login2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full h-full lg:w-1/2">
          <div class="title mt-12 mb-12">ลืมรหัสผ่าน</div>
          <!-- <label class="mb-2">เลขบัตรประชาชน</label>
          <div class="mb-1"></div>
          <input
            class="w-full mb-4"
            type="number"
            placeholder="เลขบัตรประชาชน"
            v-model="cardNumber"
          /> -->

          <label>อีเมล</label>
          <div class="mb-1"></div>
          <input
            class="w-full mb-4"
            type="email"
            placeholder="อีเมล"
            v-model="email"
          />

          <button class="mb-4 mt-8" @click="forgotPassword()">ส่งคำร้อง</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useAuth from "../hooks/useAuth";
export default {
  methods: {
    forgotPassword() {
      useAuth.forgotPassword(this.email).then((response) => {
        if (response.data.status === true) {
          this.$swal({
            title: "ส่งอีเมลสำเร็จ",
            icon: 'success',
            // html: `<div align='center'><img src='${process.env.VUE_APP_URL_API}/icon/success.svg' width='100px;' height='100px;' /></div>`,
            confirmButtonText: "ยืนยัน",
          });
        }
      });
    },
  },
  setup() {
    const cardNumber = ref(null);
    const email = ref(null);
    return { cardNumber, email };
  },
};
</script>

<style scoped>

.image {
  width: 50px !important;
  height: 50px !important;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */
  text-align: center;
  font-weight: bold;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.forgotPassword {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fa4238;
  text-decoration-line: underline;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

input[type=number] {
  -moz-appearance: textfield;
}

button {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  background: #573c81;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}

.register {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #242424;
}

.container {
  font-family: KanitFont;
}
</style>